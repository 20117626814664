<template>
     <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <div class="row">
                    <div class="col text-left mr-2">
                      <div class="btn-group text-left">
                        <button
                          type="button"
                          class="btn btn-outline-secondary"
                          style="font-size:12px"
                        >
                        <span :class="{'bg-success':firstAlerte,'bg-warning':secondAlerte,'bg-danger':thirthAlerte,'bg-info':zeroAlerte}" class="badge bg-info text-white font-size-14">{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(leftAmount)}}</span><br>
                        Montant Contract restant
                        </button>

                        <button
                          type="button"
                          class="btn btn-outline-secondary"
                          style="font-size:12px"
                        >
                          <span class="badge bg-success text-white font-size-14">{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(contract.hosting_amnt)}}</span><br>
                        Montant Contract
                        </button>

                        <button
                          type="button"
                          class="btn btn-outline-secondary"
                          style="font-size:12px"
                        >
                        <span class="badge bg-secondary text-white font-size-14">{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(ContractODSAmount)}} </span><br>
                          Montant ODS Mensuel
                        </button>

                        <!-- <button
                          type="button"
                          class="btn btn-outline-secondary active font-size-12"
                          style="font-size:10px"
                        >
                          <span class="badge bg-primary text-white font-size-12">{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(getTotalAmountODS())}}</span><br>
                            <span class="badge bg-primary text-white font-size-12" v-else>{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(0)}}</span><br>
                          Montant ODS en cours 
                        </button> -->
                        
                      </div>
                    </div>
                    
                </div>
              </div>

                <div class="col-lg-4">
                    
                    <div class="">
                        

                        <div class="row">
                        <div class="col-6">
                            <p class="text-muted mb-4">
                                <i
                                    class="mdi mdi-chart-areaspline h2 text-warning align-middle mb-0 me-3"
                                ></i>
                                Montant ODS
                            </p>
                            <div>
                            <h5>{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(totalOds)}}</h5>
                            <!-- <h5 v-else-if="selectedOds.length > 0">{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(totalOds)}}</h5> -->
                            <p class="text-muted text-truncate mb-0" :class="{'text-danger':perc>0,'text-succes':perc<0}">
                                {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(calComp())}} ( {{perc}} % )
                                <i v-if="perc < 0" class="mdi mdi-arrow-down ms-1 text-success"></i>
                                <i v-else class="mdi mdi-arrow-up ms-1 text-danger"></i>
                            </p>
                            </div>
                        </div>
                        <div class="col-6">
                            <div>
                            <apexchart
                                class="apex-charts"
                                height="120"
                                type="area"
                                dir="ltr"
                                :series="series"
                                :options="chartOptions"
                            ></apexchart>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->
          </div>
        </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
    // props:['selectedContract','orders','total_month','selectedOds'],
    props:{
        selectedContract:{
            type:Object,
        },
        orders:{
            type:Array
        },
        selectedOds:{
            type:Array
        },
        total_month:{
            type:Number
        },
        total_ods:{
            type:Number,
            default:0
        },

    },
    
    data() {
        return {
            perc:0,
            contract:{hosting_amnt:0},
            first:0,
            second:0,
            thirth:0,
            zeroAlerte:true,
            firstAlerte:false,
            secondAlerte:false,
            thirthAlerte:false,
            leftAmount:0,
            ContractODSAmount:0,
            totalOds:0,
            disabled:false,
            series: [{
                name: 'Montant ODS',
                data: []
                // data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14]
            }],
            chartOptions: {
                chart: {
                    type: 'area',
                    height: 40,
                    sparkline: {
                        enabled: true
                    }
                },
                stroke: {
                    curve: 'smooth',
                    width: 2,
                },
                title: {
                    text: 'Les 10 Derniers ODS',
                    align: 'center',
                    margin:0,
                    style: {
                        fontSize:  '10px',
                    },
                },
                colors: ['#f1b44c'],
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        inverseColors: false,
                        opacityFrom: 0.45,
                        opacityTo: 0.05,
                        stops: [0,25, 100, 100, 100]
                    },
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        formatter: function (val) {
                            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(val)
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            }
        }
    },
    watch:{
        orders:{
            handler(val){
                console.log(val.map((x)=>x.totalAmnt.plain));
                let vals = this.orders.map((x)=>x.totalAmnt.plain);
                this.series=[{name:'Montant ODS',data:vals.reverse()}] ;

            }
        },
        selectedOds:{
            handler(val){
                if(val.length==0) this.totalOds=0;
            }
        },
        selectedContract:{
            handler(val){
                console.log(val);
                this.contract = val;
                this.first = (parseInt(this.contract.hosting_amnt) * parseInt(this.contract.amnt_first_alert)) / 100;
                this.second = (parseInt(this.contract.hosting_amnt) * parseInt(this.contract.amnt_second_alert)) / 100;
                this.thirth = (parseInt(this.contract.hosting_amnt) * parseInt(this.contract.amnt_third_alert)) / 100;
                // console.log([this.first,this.second,this.thirth]);

                let ods = this.orders.filter(x => x.contract.id == this.contract.id);

                let total = ods.reduce(function(carry,item){
                                return carry + parseInt(item.totalAmnt.plain)
                            },0);
                this.ContractODSAmount = total;
                let left = parseInt(this.contract.hosting_amnt) - parseInt(total);

                if (left > 0) this.leftAmount = left;

                if((total >= this.first) && (total < this.second)) Swal.fire("1ére Alerte!!", "Le niveau consommation de la 1ere alerte a été atteinte", "success");
                else if((total >= this.second) && (total < this.thirth)) Swal.fire("2eme Alerte!!", "Le niveau consommation de la 2eme alerte a été atteinte", "warning");
                else if(total >= this.thirth) Swal.fire("3éme Alerte!", "Le niveau consommation de le niveau 3eme alerte a été atteinte", "danger");
            }
        },
        total_ods:{
            handler(val){
                this.totalOds = val;
                // if((val >= this.first) && (val < this.second)) {Swal.fire("1ére Alerte!", "Le niveau consommation de la 1ere alerte a été atteinte", "success"); this.firstAlerte = true; this.$emit("disableAll",false)}
                // else if((val >= this.second) && (val < this.thirth)) {Swal.fire("2eme Alerte!", "Le niveau consommation de la 2eme alerte a été atteinte", "warning");this.secondAlerte = true; this.firstAlerte = false;}
                // else if((val >= this.thirth) && (val < this.leftAmount)){ Swal.fire("3éme Alerte!", "Le niveau consommation de la 3eme alerte a été atteinte", "error");this.thirthAlerte = true;this.secondAlerte = false; this.firstAlerte = false;}
                // else if (val >= this.leftAmount) {Swal.fire("Erreur", "Le niveau consommation voulu a dépassé le montant restant du contract", "error"); this.$emit("disableAll",true)}
            }
        },
        totalOds:{
            handler(val){
                // this.totalOds = val;
                if((val >= this.first) && (val < this.second)) {Swal.fire("1ére Alerte!", "Le niveau consommation de la 1ere alerte a été atteinte", "success"); this.firstAlerte = true; this.$emit("disableAll",false)}
                else if((val >= this.second) && (val < this.thirth)) {Swal.fire("2eme Alerte!", "Le niveau consommation de la 2eme alerte a été atteinte", "warning");this.secondAlerte = true; this.firstAlerte = false;}
                else if((val >= this.thirth) && (val < this.leftAmount)){ Swal.fire("3éme Alerte!", "Le niveau consommation de la 3eme alerte a été atteinte", "error");this.thirthAlerte = true;this.secondAlerte = false; this.firstAlerte = false;}
                else if (val > this.leftAmount) {
                    Swal.fire("Erreur", "Le niveau consommation voulu a dépassé le montant restant du contract", "error"); 
                    this.$emit("disableAll",true),
                    this.thirthAlerte = false;this.secondAlerte = false; this.firstAlerte = false;
                }
                else this.zeroAlerte = true;
            }
        },
    },

    methods:{
        calComp(){
            if(parseInt(this.total_ods) > 0){
                let hier = this.orders[0] ? parseInt(this.orders[0].totalAmnt.plain) : 0;
                let auj = parseInt(this.total_ods);

                let diff = auj - hier;

                let perc = (diff * 100)/hier;

                this.perc = perc.toFixed(2);

                return Math.abs(diff);
            }

            if(this.selectedOds[0] && this.selectedOds[0].totalAmnt.plain){
                let i = this.orders.indexOf(this.selectedOds[0]);
                let hier;
                if(i+1 >= this.orders.length) hier = parseInt(this.orders[i].totalAmnt.plain);
                else hier = parseInt(this.orders[i+1].totalAmnt.plain);
                let auj = parseInt(this.selectedOds[0].totalAmnt.plain);
                    this.contract = this.selectedOds[0].contract;
                    this.totalOds = parseInt(this.selectedOds[0].totalAmnt.plain);
                let diff = auj - hier;
                this.first = (parseInt(this.contract.hosting_amnt) * parseInt(this.contract.amnt_first_alert)) / 100;
                this.second = (parseInt(this.contract.hosting_amnt) * parseInt(this.contract.amnt_second_alert)) / 100;
                this.thirth = (parseInt(this.contract.hosting_amnt) * parseInt(this.contract.amnt_third_alert)) / 100;

                let ods = this.orders.filter(x => x.contract.id == this.contract.id);

                let total = ods.reduce(function(carry,item){
                    return carry + parseInt(item.totalAmnt.plain)
                },0);
                this.ContractODSAmount = total;
                let left = parseInt(this.contract.hosting_amnt) - parseInt(total);

                if (left > 0) this.leftAmount = left;

                if((total >= this.first) && (total < this.second)) Swal.fire("1ére Alerte!!", "Le niveau consommation de la 1ere alerte a été atteinte", "success");
                else if((total >= this.second) && (total < this.thirth)) Swal.fire("2eme Alerte!!", "Le niveau consommation de la 2eme alerte a été atteinte", "warning");
                else if(total >= this.thirth) Swal.fire("3éme Alerte!", "Le niveau consommation de le niveau 3eme alerte a été atteinte", "danger");

                let perc = (diff * 100)/hier;

                this.perc = perc.toFixed(2);

                return Math.abs(diff);
            }
            else {this.totalOds = 0; return 0}

        
        }
    },

    // calLeftAmount(){
    //     this.first = (parseInt(this.contract.catering_amnt) * this.contract.amnt_first_alert) / 100;
    //     this.second = (parseInt(this.contract.catering_amnt) * this.contract.amnt_second_alert) / 100;
    //     this.thirth = (parseInt(this.contract.catering_amnt) * this.contract.amnt_third_alert) / 100;

    //     let ods = this.orders.filter(x => x.contract.id == this.contract.id);

    //     let total = ods.reduce(function(carry,item){
    //         return carry + item.totalAmnt.plain
    //     },0);
    //     this.ContractODSAmount = total;
    //     let left = parseInt(this.contract.catering_amnt) - parseInt(total);

    //     if (left > 0) this.leftAmount = left;

    //     if((total >= this.first) && (total < this.second)) Swal.fire("1ére Alerte!!", "Le niveau consommation de la 1ere alerte a été atteinte", "success");
    //     else if((total >= this.second) && (total < this.thirth)) Swal.fire("2eme Alerte!!", "Le niveau consommation de la 2eme alerte a été atteinte", "warning");
    //     else if(total >= this.thirth) Swal.fire("3éme Alerte!", "Le niveau consommation de le niveau 3eme alerte a été atteinte", "danger");
    // }
}
</script>