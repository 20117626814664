var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-6" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col text-left mr-2" }, [
              _c("div", { staticClass: "btn-group text-left" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-secondary",
                    staticStyle: { "font-size": "12px" },
                    attrs: { type: "button" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "badge bg-info text-white font-size-14",
                        class: {
                          "bg-success": _vm.firstAlerte,
                          "bg-warning": _vm.secondAlerte,
                          "bg-danger": _vm.thirthAlerte,
                          "bg-info": _vm.zeroAlerte
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "DZD"
                            }).format(_vm.leftAmount)
                          )
                        )
                      ]
                    ),
                    _c("br"),
                    _vm._v(" Montant Contract restant ")
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-secondary",
                    staticStyle: { "font-size": "12px" },
                    attrs: { type: "button" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "badge bg-success text-white font-size-14"
                      },
                      [
                        _vm._v(
                          _vm._s(
                            new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "DZD"
                            }).format(_vm.contract.hosting_amnt)
                          )
                        )
                      ]
                    ),
                    _c("br"),
                    _vm._v(" Montant Contract ")
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-secondary",
                    staticStyle: { "font-size": "12px" },
                    attrs: { type: "button" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "badge bg-secondary text-white font-size-14"
                      },
                      [
                        _vm._v(
                          _vm._s(
                            new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "DZD"
                            }).format(_vm.ContractODSAmount)
                          ) + " "
                        )
                      ]
                    ),
                    _c("br"),
                    _vm._v(" Montant ODS Mensuel ")
                  ]
                )
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "col-lg-4" }, [
          _c("div", {}, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm._m(0),
                _c("div", [
                  _c("h5", [
                    _vm._v(
                      _vm._s(
                        new Intl.NumberFormat("de-DE", {
                          style: "currency",
                          currency: "DZD"
                        }).format(_vm.totalOds)
                      )
                    )
                  ]),
                  _c(
                    "p",
                    {
                      staticClass: "text-muted text-truncate mb-0",
                      class: {
                        "text-danger": _vm.perc > 0,
                        "text-succes": _vm.perc < 0
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "DZD"
                            }).format(_vm.calComp())
                          ) +
                          " ( " +
                          _vm._s(_vm.perc) +
                          " % ) "
                      ),
                      _vm.perc < 0
                        ? _c("i", {
                            staticClass: "mdi mdi-arrow-down ms-1 text-success"
                          })
                        : _c("i", {
                            staticClass: "mdi mdi-arrow-up ms-1 text-danger"
                          })
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "col-6" }, [
                _c(
                  "div",
                  [
                    _c("apexchart", {
                      staticClass: "apex-charts",
                      attrs: {
                        height: "120",
                        type: "area",
                        dir: "ltr",
                        series: _vm.series,
                        options: _vm.chartOptions
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-muted mb-4" }, [
      _c("i", {
        staticClass:
          "mdi mdi-chart-areaspline h2 text-warning align-middle mb-0 me-3"
      }),
      _vm._v(" Montant ODS ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }